// import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "./authConfig";
// import { graphConfig } from "./helpers/graphService";
// import { InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

// interface User {
//   name: string;
//   profilePicture: string;
// }

// interface UserContextType {
//   user: User;
//   loading: boolean;
// }

// const UserContext = createContext<UserContextType | undefined>(undefined);

// export const useUser = (): UserContextType => {
//   const context = useContext(UserContext);
//   if (!context) {
//     throw new Error('useUser must be used within a UserProvider');
//   }
//   return context;
// };

// export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
//   const { instance } = useMsal();
//   const [user, setUser] = useState<User>({ name: '', profilePicture: '' });
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const signIn = async () => {
//       try {
//         await instance.handleRedirectPromise();
//         const accounts = instance.getAllAccounts();
//         console.log('Accounts:', accounts);
//         if (accounts.length > 0) {
//           instance.setActiveAccount(accounts[0]);
//           await fetchUserData(accounts[0]);
//         } else {
//           try {
//             const silentResponse = await instance.ssoSilent(loginRequest);
//             if (silentResponse.account) {
//               instance.setActiveAccount(silentResponse.account);
//               await fetchUserData(silentResponse.account);
//             }
//           } catch (ssoError) {
//             if (ssoError instanceof InteractionRequiredAuthError) {
//               instance.loginRedirect(loginRequest);
//             } else {
//               console.error("SSO Error:", ssoError);
//             }
//           }
//         }
//       } catch (error) {
//         console.error("Sign-in error:", error);
//         if (error instanceof InteractionRequiredAuthError) {
//           instance.loginRedirect(loginRequest);
//         } else {
//           console.error("Authentication error:", error);
//         }
//       } finally {
//         setLoading(false);
//       }
//     };

//     const fetchUserData = async (account: AccountInfo) => {
//       try {
//         const accessToken = await instance.acquireTokenSilent({
//           ...loginRequest,
//           account: account,
//         });

//         const response = await fetch(graphConfig.graphMeEndpoint, {
//           headers: {
//             Authorization: `Bearer ${accessToken.accessToken}`,
//           },
//         });
//         const userData = await response.json();

//         const photoResponse = await fetch(`${graphConfig.graphMeEndpoint}/photo/$value`, {
//           headers: {
//             Authorization: `Bearer ${accessToken.accessToken}`,
//           },
//         });
//         const photoBlob = await photoResponse.blob();
//         const photoUrl = URL.createObjectURL(photoBlob);

//         setUser({
//           name: userData.displayName,
//           profilePicture: photoUrl,
//         });

//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     signIn();
//   }, [instance]);

//   return (
//     <UserContext.Provider value={{ user, loading }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { graphConfig } from "./helpers/graphService";
import { InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

interface User {
  name: string;
  profilePicture: string;
}

interface UserContextType {
  user: User;
  loading: boolean;
  setIsLoggingIn: (value: boolean) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { instance } = useMsal();
  const [user, setUser] = useState<User>({ name: '', profilePicture: '' });
  const [loading, setLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    const signIn = async () => {
      try {
        if (isLoggingIn) {
          const silentResponse = await instance.ssoSilent(loginRequest);
          if (silentResponse.account) {
            instance.setActiveAccount(silentResponse.account);
            await fetchUserData(silentResponse.account);
          }
        } else {
          try {
            await instance.handleRedirectPromise();
            const accounts = instance.getAllAccounts();
            console.log('Accounts:', accounts);
            if (accounts.length > 0) {
              instance.setActiveAccount(accounts[0]);
              await fetchUserData(accounts[0]);
            }
          } catch (ssoError) {
            if (ssoError instanceof InteractionRequiredAuthError) {
              instance.loginRedirect(loginRequest);
            } else {
              console.error("SSO Error:", ssoError);
            }
          }
        }
      } catch (error) {
        console.error("Sign-in error:", error);
        if (error instanceof InteractionRequiredAuthError) {
          instance.loginRedirect(loginRequest);
        } else {
          console.error("Authentication error:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    signIn();
  }, [instance, isLoggingIn]);

  const fetchUserData = async (account: AccountInfo) => {
    try {
      const accessToken = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

      const response = await fetch(graphConfig.graphMeEndpoint, {
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      });
      const userData = await response.json();

      const photoResponse = await fetch(`${graphConfig.graphMeEndpoint}/photo/$value`, {
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      });
      const photoBlob = await photoResponse.blob();
      const photoUrl = URL.createObjectURL(photoBlob);

      setUser({
        name: userData.displayName,
        profilePicture: photoUrl,
      });

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <UserContext.Provider value={{ user, loading, setIsLoggingIn }}>
      {children}
    </UserContext.Provider>
  );
};
