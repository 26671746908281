
import { Configuration, LogLevel } from '@azure/msal-browser';

const isLocal = window.location.hostname === 'localhost';

export const msalConfig: Configuration = {
  auth: {
    clientId: '51160856-a900-4874-bafa-4e3034b3f82a', // Replace with your client ID
    authority: 'https://login.microsoftonline.com/d8c77473-17b9-4474-832c-de869dcae82c', // Replace with your tenant ID
    redirectUri: isLocal 
      ? 'http://localhost:3000/auth/login'
      : process.env.REACT_APP_REDIRECT_URI,

   
  
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {	
    loggerOptions: {	
        loggerCallback: (level, message, containsPii) => {	
            if (containsPii) {		
                return;		
            }		
            switch (level) {
                case LogLevel.Error:
                     console.error(message);
                    return;
                case LogLevel.Info:
                     console.info(message);
                    return;
                case LogLevel.Verbose:
                     console.debug(message);
                    return;
                case LogLevel.Warning:
                     console.warn(message);
                    return;
                default:
                    return;
            }	
        }	
    }	
},
};
