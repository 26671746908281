// // import React from "react";

// // import AllRoutes from "./routes/Routes";

// // import { configureFakeBackend } from "./helpers";


// // // For Default import Default.scss
// // import './assets/scss/Default.scss';

// // // For Saas import Saas.scss
// // // import './assets/scss/Saas.scss';

// // // For Modern demo import Modern.scss
// // // import './assets/scss/Modern.scss';

// // // For Creative demo import Creative.scss
// // // import "./assets/scss/Creative.scss";

// // // For Purple demo import Purple.scss
// // // import './assets/scss/Purple.scss';

// // // For Material demo import Material.scss
// // // import './assets/scss/Material.scss';


// // // Other
// // import './assets/scss/Landing.scss';
// // import "./assets/scss/Icons.scss";

// // // configure fake backend
// // configureFakeBackend();

// // const App = () => {
// //   return (
// //     <>
// //       <React.Fragment>
// //       <AllRoutes />
// //       </React.Fragment>
// //     </>
// //   );
// // };

// // export default App;

import React from 'react';
import { Spinner } from "react-bootstrap";
import AllRoutes from "./routes/Routes";
import { configureFakeBackend } from "./helpers";
import './assets/scss/Default.scss';
import './assets/scss/Landing.scss';
import "./assets/scss/Icons.scss";
import { useUser } from './userContext';
import { AuthenticatedTemplate } from "@azure/msal-react";
import Login from './pages/auth/Login';

configureFakeBackend();

const App = () => {
  const { user, loading } = useUser();

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="grow" variant="primary" className="display-1" />
      </div>
    );
  }

  // If the user is not authenticated, show the custom login page
  if (!user.name) {
    return <Login />;
  }

  return(
    <AuthenticatedTemplate>
      <AllRoutes />
    </AuthenticatedTemplate>
  );
};

export default App;
